import { BrandL2RouteData } from "~/server/data/brand_l2_route_data";
import { SimulatedTransactionDetails } from "~/server/types/brand";

export const getSimulateTransactionDetails = (
  routeData: () => BrandL2RouteData | undefined
): SimulatedTransactionDetails | null => {
  const simulate = routeData()?.simulate;
  return simulate && "initialSimulateTransactionDetails" in simulate
    ? simulate.initialSimulateTransactionDetails
    : null;
};

export const getSimulateError = (
  routeData: () => BrandL2RouteData | undefined
): string | null => {
  const simulate = routeData()?.simulate;
  return simulate && "initialSimulateError" in simulate
    ? simulate.initialSimulateError
    : null;
};
