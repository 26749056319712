import rehypeRaw from "rehype-raw";
import { createSignal, For, Show } from "solid-js";
import SolidMarkdown from "solid-markdown";
import { FootNote } from "~/server/types/brand";
import { rehypeUppercaseATags } from "~/utils/common";
import { Faq } from "../faq";

type BrandL2FooterNotesProps = {
  footerList: FootNote[];
};

export default function BrandL2FooterNotes(props: BrandL2FooterNotesProps) {
  const [openFaqSerialNumber, setOpenFaqSerialNumber] = createSignal<number>();

  return (
    <Show when={props.footerList.length > 0}>
      {/* max-width is fixed same as [brandkey].tsx  */}
      <div class="w-full max-w-[573px] px-4 lg:max-w-[1011px]">
        <div class="py-12">
          <div class="h-[1px] w-full bg-baseTertiaryDark "></div>
        </div>

        <For each={props.footerList}>
          {(faq, index) => (
            <Faq
              sNo={index() + 1}
              question={faq.title}
              answer={faq.description}
              openFaqSerialNumber={openFaqSerialNumber}
              setOpenFaqSerialNumber={setOpenFaqSerialNumber}
              className={"px-0"}
            />
          )}
        </For>
      </div>
    </Show>
  );
}
