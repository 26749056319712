import {
  cache,
  createAsync,
  useIsRouting,
  useNavigate,
  useParams,
  useSearchParams,
} from "@solidjs/router";
import { animate } from "motion";
import {
  Accessor,
  Match,
  Show,
  Switch,
  createEffect,
  createMemo,
  createSignal,
  onMount,
  untrack,
} from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { HubbleAssured } from "~/components/brand/assured";
import { SecondaryCtas } from "~/components/brand/secondary_ctas";
import { AboutTheBrand } from "~/components/brand_l2/about_amazon";
import { AboutThisVoucher } from "~/components/brand_l2/about_this_card";
import { BrandTitle } from "~/components/brand_l2/brandTitle";
import {
  CtaState,
  DenominationsGrid,
} from "~/components/brand_l2/denominations_grid";
import { Faqs } from "~/components/brand_l2/faqs";
import {
  Header,
  isFreshLogin,
  setIsFreshLogin,
} from "~/components/brand_l2/header";
import { SimilarBrands } from "~/components/brand_l2/similar_brands";
import { TrustMarkerHappyUsers } from "~/components/brand_l2/trust_marker_happy_users";
import { VoucherGenerationBrandCard } from "~/components/brand_l2/voucher_generation_brand_card";
import { EmailTip, WhatsappTip } from "~/components/brand_l2/whatsapp_tip";
import { Footer } from "~/components/footer";
import {
  HubbleEvent,
  RNREvent,
  rnrEventManager,
  webEventManager,
} from "~/data/events";
import { logIntoHubble } from "~/screens/hubble_otp_auth/hubble_otp_auth";
import { PaymentLoader } from "~/screens/payment_options/payment_loader";
import {
  getCoinsSummary,
  initiateRedemption,
  simulate,
  verifyUser,
} from "~/server/apis/client_apis";
import {
  BrandL2RouteData,
  getBrandL2RouteData,
} from "~/server/data/brand_l2_route_data";
import { VerificationPlatform } from "~/server/types/auth";
import { goBack, HubbleRoute, pushPage } from "~/shared_states/modal";
import { showSnackBar, snackApiCallWrapper } from "~/shared_states/snackbar";
import { Cookie, Optional } from "~/types";
import attachPopStateListener from "~/utils/popstate_listener";
import { Divider } from "~/widgets/divider";
import { DottedLoader } from "~/widgets/loader";
import { Spacer } from "~/widgets/spacer";
import { SdkTopRightCloseButton } from "~/components/sdk_tr_close_button";
import { Link, Meta, Title } from "@solidjs/meta";
import {
  getBrandKeyFromSlug,
  getCategorySlugFromCategoryName,
  getClientIdFromCookie,
} from "~/utils/common";
import { SeoSchema, MetadataTags } from "~/components/brand_l2/seo";
import { getCookie } from "~/utils/client_cookie";
import { fraudChecksLog } from "~/utils/logging";
import HubbleImage from "~/widgets/hubble_image";
import BrandL2FooterNotes from "~/components/brand_l2/footer_notes";
import { BreadcrumbsNav } from "~/widgets/breadcrumbs";
import { config } from "~/data/config";
import { HubbleBranding } from "~/widgets/branding";
import {
  DiscountDetailsItem,
  ClientMinimalConfigOld,
  SimulatedTransactionDetails,
  PreReviewPopupDetails,
} from "~/server/types/brand";
import { ClientConfig } from "~/server/types/client";
import {
  getSimulateError,
  getSimulateTransactionDetails,
} from "~/utils/brandl2";
import { WarningModal } from "~/screens/warning_modal/warning_modal";
import { useModal } from "~/components/modal";
import UserInfoModal from "~/components/login-rnr/user_info_modal";

const getBrandL2RouteData$C = cache(getBrandL2RouteData, "brand-l2");

async function runFingerprinting(
  routeData: Accessor<BrandL2RouteData | undefined>
) {
  const isLoggedIn = routeData()?.isLoggedIn;
  if (!isLoggedIn) {
    fraudChecksLog("Skipping fraud checks. User is not logged in");
    return;
  }

  if (!routeData()?.userProfile) {
    fraudChecksLog("Skipping fraud checks. User profile is not present.");
    return;
  }

  if (!(routeData()?.clientConfig as ClientConfig)?.fraudChecksEnabled) {
    fraudChecksLog("Skipping fraud checks. fraud checks disabled for client");
    return;
  }

  const verificationStatus =
    routeData()?.userProfile?.verificationDetails?.deviceVerificationStatus;

  if (
    verificationStatus == "VERIFIED" ||
    verificationStatus == "VERIFICATION_FAILED"
  ) {
    fraudChecksLog(
      "Skipping fraud checks. Verification status set for user already"
    );
    return;
  }

  await new Promise((resolve, reject) => {
    // @ts-ignore
    FingerPrint.runWithCallback(async (requestId, visitorId, error) => {
      if (error) {
        fraudChecksLog(`could not get visitor id. ${error}`);
        fraudChecksLog(`finger printing error`);
        reject(error);
        return;
      }

      await verifyUser(requestId, VerificationPlatform.FingerPrintJs);
      fraudChecksLog(`completed sending device info to the backend`);
      resolve(true);
    });
  });
}

function animateDueAmount(
  currentDueAmount: number,
  newDueAmount: number,
  onAnimationComplete: () => void
) {
  const start = currentDueAmount;
  const diff = newDueAmount - start;
  const elements = document.querySelectorAll(".anim-due-amount")!;
  animate(
    (progress) => {
      if (elements[0]?.innerHTML && elements[1]?.innerHTML) {
        if (progress == 1) {
          onAnimationComplete();
          elements[0].innerHTML = "" + newDueAmount;
          elements[1].innerHTML = "" + newDueAmount;
        } else {
          const toAdd = progress * diff;
          elements[0].innerHTML = "" + (start + toAdd).toFixed(0);
          elements[1].innerHTML = "" + (start + toAdd).toFixed(0);
        }
      } else if (progress == 1) {
        onAnimationComplete();
      }
    },
    {
      duration: 0.5,
      easing: "linear",
    }
  );
}

export const route = {
  load: async (context: any) => {
    try {
      return await getBrandL2RouteData$C(
        context.params.brandKey.replace("-gift-card", ""),
        {
          clientId: context.location.search.clientId,
          clientSecret: context.location.search.clientSecret,
          token: context.location.search.token,
        }
      );
    } catch (e) {
      // console.log(e);
    }
  },
};

export default function BrandPage() {
  onMount(() => {
    let clientHasLatestSid =
      getCookie(Cookie.SessionId) === routeData()?.sdkMetaData?.sid;
    console.log(`clientHasLatestSid: ${clientHasLatestSid}`);
    let isHubble = getMode() == "hubble";
    if (!clientHasLatestSid && !isHubble) {
      localStorage.setItem("sessionId", routeData()!.sdkMetaData?.sid!);
      console.log(`setting sid to ${routeData()!.sdkMetaData?.sid}`);
    }
    attachPopStateListener();
  });

  const params = useParams();

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const isRouting = useIsRouting();

  const routeData: Accessor<BrandL2RouteData | undefined> =
    createAsync<BrandL2RouteData>(
      () => {
        return getBrandL2RouteData$C(getBrandKeyFromSlug(params.brandKey), {
          clientId: queryParams.clientId,
          clientSecret: queryParams.clientSecret,
          token: queryParams.token,
        });
      },
      {
        deferStream: true,
      }
    );

  const [selectedAmount, setSelectedAmount] = createSignal<Optional<number>>(
    getSimulateTransactionDetails(routeData)?.redemptionAmount ?? null
  );

  const [selectedVariantId, setSelectedVariantId] = createSignal<
    Optional<string>
  >(
    routeData()?.brand?.variantDetailedResponse?.find(
      (variant) => variant.isDefault
    )?.variantId ?? ""
  );

  const [rewardDiscountAmount, setRewardDiscountAmount] = createSignal(0);

  const [initialErrorOnSimulate, setInitialErrorOnSimulate] = createSignal<
    Optional<string>
  >(getSimulateError(routeData) ?? null);

  const [dryRunErrorMessage, setDryRunErrorMessage] =
    createSignal<Optional<string>>(null);

  const [dueAmount, setDueAmount] = createSignal<Optional<number>>(
    getSimulateTransactionDetails(routeData)
      ? getDueAmount(getSimulateTransactionDetails(routeData)!)
      : null
  );
  const [redemptionAmount, setRedemptionAmount] = createSignal<
    Optional<number>
  >(getSimulateTransactionDetails(routeData)?.redemptionAmount ?? null);

  const [ctaState, setCtaState] = createSignal<CtaState>("DEFAULT");
  const [isSimulating, setIsSimulating] = createSignal<boolean>(false);

  const [useRewardCoins, setUseRewardCoins] = createSignal<null | boolean>(
    null
  );

  const [hubbleCoinRewards, setHubbleCoinRewards] =
    createSignal<null | DiscountDetailsItem>(
      routeData()?.hubbleCoinRewards ?? null
    );

  const [latestCoinsBalance, setLatestCoinsBalance] = createSignal<
    number | undefined
  >(undefined);

  const [refreshLatestBalance, setRefreshLatestBalance] = createSignal(false);

  const {
    setIsModalOn,
    updateModalContentGetter,
    setOuterFrameClass,
    closeModal,
  } = useModal()!;

  let fraudChecksCompleted = false;

  onMount(() => {
    rnrEventManager.setClientId(getClientIdFromCookie() ?? "");
    rnrEventManager.sendEvent(RNREvent.VISIT_BRANDL2_PAGE);
  });

  // To set the selected amount post navigating to the brand page from some other page
  createEffect(() => {
    setHubbleCoinRewards(routeData()?.hubbleCoinRewards ?? null);

    setSelectedVariantId(
      routeData()?.brand?.variantDetailedResponse?.find(
        (variant) => variant.isDefault
      )?.variantId ?? ""
    );

    setDueAmount(
      getSimulateTransactionDetails(routeData)
        ? getDueAmount(getSimulateTransactionDetails(routeData)!)
        : null
    );
    setRedemptionAmount(
      getSimulateTransactionDetails(routeData)?.redemptionAmount ?? null
    );

    setInitialErrorOnSimulate(getSimulateError(routeData));
    setDryRunErrorMessage(null);

    setSelectedAmount(
      getSimulateTransactionDetails(routeData)?.redemptionAmount ?? null
    );
  });

  createEffect(async () => {
    const rewardCoins = useRewardCoins();

    if (rewardCoins !== null) {
      await simulateTransaction(rewardCoins);
    }
  });

  async function simulateTransaction(consumeCoins: boolean) {
    await untrack(async () => {
      setIsSimulating(true);
      setCtaState("DISABLED");

      try {
        const newSimulateResponse = await simulate({
          voucherProductId: routeData()?.brand.externalId!,
          amount: redemptionAmount()!,
          variantId: selectedVariantId() ?? "",
          consumeCoins,
        });

        const newSimulateTransactionDetails = newSimulateResponse.data.filter(
          (data) => data.paymentRoute != "WALLET"
        )[0];

        const newSimulateHubbleRewardsDetails = newSimulateResponse.data
          .filter(({ paymentMode }) => paymentMode === "UPI")
          .map(({ discountDetails }) => {
            const { totalDiscount, coinDiscount } = discountDetails;

            return {
              hubbleCoinRewards: {
                amount: totalDiscount.amount - coinDiscount.amount,
                percentage: totalDiscount.percentage - coinDiscount.percentage,
              },
            };
          });

        setRewardDiscountAmount(
          newSimulateTransactionDetails.discountDetails.coinDiscount.amount
        );

        setHubbleCoinRewards(
          newSimulateHubbleRewardsDetails[0].hubbleCoinRewards
        );

        let newDueAmount = getDueAmount(newSimulateTransactionDetails);

        animateDueAmount(dueAmount()!, newDueAmount, () => {});

        setDueAmount(newDueAmount);

        setRedemptionAmount(newSimulateTransactionDetails.redemptionAmount);
        setDryRunErrorMessage(null);
      } catch (e: any) {
        console.log(`got error ${e}`);
        console.log(`got error ${e.message}`);
        setDryRunErrorMessage(e.message);
      } finally {
        setIsSimulating(false);
        setCtaState("DEFAULT");
      }
    });
  }

  // createEffect(async () => {
  //   const newAmount = selectedAmount();
  //   const rewardCoins = useRewardCoins();

  //   if (!newAmount) return;

  //   await untrack(async () => {
  //     if (newAmount === redemptionAmount()) {
  //       setDryRunErrorMessage(null);
  //       return;
  //     }

  //     const consumeCoins = getMode() === "rnr" || rewardCoins === true

  //     await simulateTransaction(consumeCoins, newAmount);
  //   });
  // });

  createEffect(async () => {
    const newAmount = selectedAmount();
    const rewardCoins = useRewardCoins();

    if (!newAmount) return;
    await untrack(async () => {
      if (newAmount == redemptionAmount()) {
        setDryRunErrorMessage(null);
        return;
      }
      setIsSimulating(true);
      setCtaState("DISABLED");
      try {
        const newSimulateResponse = await simulate({
          voucherProductId: routeData()?.brand.externalId!,
          amount: newAmount,
          variantId: selectedVariantId() ?? "",
          consumeCoins: getMode() == "rnr" || rewardCoins === true,
        });

        const newSimulateTransactionDetails = newSimulateResponse.data.filter(
          (data) => data.paymentRoute != "WALLET"
        )[0];

        const newSimulateHubbleRewardsDetails = newSimulateResponse.data
          .filter(({ paymentMode }) => paymentMode === "UPI")
          .map(({ discountDetails }) => {
            const { totalDiscount, coinDiscount } = discountDetails;

            return {
              hubbleCoinRewards: {
                amount: totalDiscount.amount - coinDiscount.amount,
                percentage: totalDiscount.percentage - coinDiscount.percentage,
              },
            };
          });

        setHubbleCoinRewards(
          newSimulateHubbleRewardsDetails[0].hubbleCoinRewards
        );

        setRewardDiscountAmount(
          newSimulateTransactionDetails.discountDetails.coinDiscount.amount
        );

        let newDueAmount = getDueAmount(newSimulateTransactionDetails);

        animateDueAmount(dueAmount()!, newDueAmount, () => {});

        setDueAmount(newDueAmount);

        setRedemptionAmount(newSimulateTransactionDetails.redemptionAmount);
        setDryRunErrorMessage(null);
      } catch (e: any) {
        console.log(`got error ${e}`);
        console.log(`got error ${e.message}`);
        setDryRunErrorMessage(e.message);
      } finally {
        setIsSimulating(false);
        setCtaState("DEFAULT");
      }
    });
  });

  function getDueAmount(
    simulatedTransactionDetails: SimulatedTransactionDetails
  ) {
    if (simulatedTransactionDetails.paymentSource == "COINS_ONLY") {
      return simulatedTransactionDetails.totalRequiredCoins;
    } else {
      return simulatedTransactionDetails.dueAmount;
    }
  }

  const onClickGetThisCard = async () => {
    // check login and initiate order
    if (
      routeData()?.userProfile &&
      routeData()?.userProfile?.phoneNumber == null
    ) {
      setIsModalOn(false);
      updateModalContentGetter(() => <></>);

      updateModalContentGetter(() => {
        return (
          <UserInfoModal
            initiateOrder={async () => {
              if (!(isFreshLogin() ?? routeData()?.isLoggedIn)) {
                logIntoHubble(async () => {
                  await onAfterLogin();
                }, webEventManager);
              } else {
                await onAfterLogin();
              }
            }}
          />
        );
      });
      setIsModalOn(true);
      return;
    }
    if (!(isFreshLogin() ?? routeData()?.isLoggedIn)) {
      logIntoHubble(async () => {
        await onAfterLogin();
      }, webEventManager);
    } else {
      await onAfterLogin();
    }
  };

  async function onAfterLogin() {
    const preReviewPopupDetails =
      routeData()?.brand.voucherProductMetadata.preReviewPopupDetails;

    if (preReviewPopupDetails != null && getMode() === "hubble") {
      openWarningModal({
        onContinue: async () => {
          setIsModalOn(false);
          await new Promise((resolve) => setTimeout(resolve, 300));
          await initiateOrder();
        },
        preReviewPopupDetails: preReviewPopupDetails,
      });
    } else {
      await initiateOrder();
    }
  }

  function openWarningModal(props: {
    onContinue: () => void;
    preReviewPopupDetails: PreReviewPopupDetails;
  }) {
    setOuterFrameClass("sm:w-[416px]");
    updateModalContentGetter(() => {
      return (
        <WarningModal
          {...props}
          onClose={() => {
            closeModal();
          }}
        />
      );
    });
    setIsModalOn(true);
  }

  const initiateOrder = async () => {
    try {
      setCtaState("LOADING");

      if (!fraudChecksCompleted) {
        fraudChecksLog(`fraud checks not completed yet. Initiating...`);

        try {
          await runFingerprinting(routeData);
          fraudChecksCompleted = true;
        } catch (e: any) {
          fraudChecksLog(`finger printing error - ${e}`);
          showSnackBar({
            message: `Account checks failed.`,
            level: "error",
          });
          setCtaState("DEFAULT");
          return;
        }
      }

      const amount = selectedAmount();
      if (!amount) {
        throw new Error("Can't initiate order when amount is null");
      }

      const res = await snackApiCallWrapper(
        initiateRedemption({
          amount: amount,
          paymentMode: "UPI",
          productId: routeData()?.brand.externalId!,
          ...(routeData()?.brand.amountConditions.denominations != null
            ? {
                denominationDetails: [
                  {
                    denomination: amount,
                    quantity: 1,
                  },
                ],
              }
            : {}),
          variantId: selectedVariantId() ?? "",
          consumeCoins: getMode() == "rnr" || (useRewardCoins() ?? false),
        })
      );

      webEventManager.sendEvent(HubbleEvent.PAYMENT_INITIATED, {
        brandName: routeData()!.brand.name.toLowerCase(),
        amount: amount,
        paymentMode: "UPI",
        consumeCoins: getMode() == "rnr" || (useRewardCoins() ?? false),
        couponCode: "",
        productId: routeData()!.brand.externalId,
      });

      function onPaymentFailed(failureReason: string) {
        goBack(() => {
          webEventManager.sendEvent(HubbleEvent.PAYMENT_FAIL, {
            brandName: routeData()!.brand.name.toLowerCase(),
            amount: selectedAmount(),
            reason: failureReason,
          });
          navigate(
            `/buy/${routeData()!.brand.brandKey}/order` +
              `?orderId=${res.storeOrderDetails.id}` +
              `&paymentFailureReason=${failureReason}` +
              (queryParams.clientId
                ? `&clientId=${queryParams.clientId}`
                : "") +
              (queryParams.clientSecret
                ? `&clientSecret=${queryParams.clientSecret}`
                : ""),
            {
              resolve: true,
              state: getNavigationState(),
            }
          );
        });
      }

      function onPaymentComplete() {
        webEventManager.sendEvent(HubbleEvent.PAYMENT_SUCCESS, {
          brandName: routeData()!.brand.name.toLowerCase(),
          amount: selectedAmount(),
        });

        navigate(
          `/buy/${routeData()!.brand.brandKey}/order` +
            `?orderId=${res.storeOrderDetails.id}` +
            (queryParams.clientId ? `&clientId=${queryParams.clientId}` : "") +
            (queryParams.clientSecret
              ? `&clientSecret=${queryParams.clientSecret}`
              : ""),
          {
            resolve: true,
            state: getNavigationState(),
          }
        );
      }

      setCtaState("DEFAULT");

      if (res.depositPaymentDetails.status == "SUCCESS") {
        onPaymentComplete();
      } else {
        pushPage(
          () => (
            <PaymentLoader
              amount={res.depositPaymentDetails.amount}
              onPaymentComplete={async () => {
                goBack();
                await new Promise((resolve) => setTimeout(resolve, 300));
                onPaymentComplete();
              }}
              onPaymentFailed={onPaymentFailed}
              orderId={res.depositPaymentDetails.id}
              reInit={initiateOrder}
              onClose={() => {
                goBack();
              }}
              brandImageUrl={routeData()!.brand.voucherProductMetadata.logoUrl}
            />
          ),
          {
            isModal: true,
            disableCloseButton: true,
            preventClose: true,
            enterAnimation: false,
          },
          HubbleRoute.PAYMENT_LOADER
        );
      }
    } catch (error: any) {
      showSnackBar({
        message: error.message,
        level: "error",
      });
      setCtaState("DEFAULT");
    }
  };

  function getNavigationState() {
    return {
      headerLogo:
        getMode() === "rnr"
          ? (routeData()?.clientConfig as ClientMinimalConfigOld)?.clientLogoUrl
          : (routeData()?.clientConfig as ClientConfig)?.clientBrandNameLogoUrl,
      isSDK: getMode() === "sdk",
      isLoggedIn: routeData()?.isLoggedIn,
    };
  }

  type UIConfig = {
    showFooter: boolean;
    showBrandingOnBottom: boolean;
    showWhatsappTip: boolean;
    showCloseButtonOnTopRight: boolean;
    showHeaderActions: boolean;
    showBreadcrumbsNav: boolean;
    showHubbleLogoOnBrandCard: boolean;
    showHubbleTrustMarker: boolean;
    showHeader: boolean;
    showFooterNotes: boolean;
    showEmailTip: boolean;
  };

  function getMode() {
    return routeData()?.mode ?? routeData()?.sdkMetaData?.mode ?? "hubble";
  }

  function getHomePageUrl() {
    return config.paths.homePageUrl;
  }

  function getCategoryPageBasePath() {
    return config.paths.categoryPageBasePath;
  }

  const isLoggedIn = createMemo<boolean>(() => {
    return isFreshLogin() ?? false;
  });

  createEffect(() => {
    setIsFreshLogin(routeData()?.isLoggedIn ?? false);
  });

  async function fetchLatestBalance() {
    const response = await getCoinsSummary();
    setLatestCoinsBalance(response.totalAvailable);
  }

  createEffect(async () => {
    if (refreshLatestBalance()) {
      await fetchLatestBalance();
      setRefreshLatestBalance(false);
    }
  });

  function getUIConfig(): UIConfig {
    let mode = getMode();
    let isSdk = mode == "sdk";
    let isRnr = mode == "rnr";
    let isHubble = mode == "hubble";
    return {
      showFooter: isHubble,
      showBrandingOnBottom: isRnr,
      showWhatsappTip: (isHubble || isRnr) && !isClientAxis(),
      showCloseButtonOnTopRight: isSdk,
      showHeaderActions: isHubble || isRnr,
      showBreadcrumbsNav: isHubble || isRnr,
      showHubbleLogoOnBrandCard: isHubble || isRnr,
      showHubbleTrustMarker: isHubble,
      showHeader: isHubble || isRnr,
      showFooterNotes: isHubble,
      showEmailTip: isClientAxis(),
    };
  }

  function getMetaTitle(): string {
    const baseDiscountPercent =
      getSimulateTransactionDetails(routeData)?.discountDetails
        .hubbleBaseDiscount.percentage ?? 0;

    if (baseDiscountPercent > 0) {
      return `${routeData()!.brand.voucherProductMetadata.title} Gift Card ${routeData()?.brand.rewardType != "POSTPAID" ? `${baseDiscountPercent}% flat off | Hubble Money` : ""} `;
    } else
      return `${routeData()!.brand.voucherProductMetadata.title} Gift Card | Hubble Money`;
  }

  createEffect(async () => {
    if (isLoggedIn()) {
      await fetchLatestBalance();
    }
  });

  const getCoins = createMemo<number>(() => {
    return (
      latestCoinsBalance() ?? routeData()?.coinsSummary?.totalAvailable ?? 0
    );
  });

  const isClientAxis = createMemo(() => {
    return routeData()?.clientId === config.axisClientId;
  });

  return (
    <>
      <ClientOnlyComponent component={ClientComponent.ModalHost} />
      <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
      <div>
        <Show when={routeData() && getUIConfig().showHeader}>
          <Header
            headerImageUrl={
              getMode() === "rnr" || getMode() === "sdk"
                ? routeData()?.clientConfig?.clientLogoUrl
                : undefined
            }
            showActions={getUIConfig().showHeaderActions}
            isLoggedIn={isLoggedIn}
            mode={getMode()}
            coins={getCoins}
            showCoins={() => getMode() === "hubble" || getMode() === "rnr"}
            setRefreshCoins={setRefreshLatestBalance}
            clientId={routeData()?.clientId}
          />
        </Show>

        <Show when={getUIConfig().showCloseButtonOnTopRight}>
          <SdkTopRightCloseButton />
        </Show>
        <Show
          when={routeData() && !isRouting()}
          fallback={<DottedLoader color="#999" />}
        >
          <div class="flex flex-col items-center justify-center gap-6">
            <div class="m-auto mt-3 flex flex-row lg:mt-5 lg:w-[1011px] lg:gap-10">
              <div class="max-w-[573px] lg:ml-0 lg:mr-0">
                <div class="mx-4 flex flex-col  gap-0.5 lg:mx-0">
                  <Meta name="robots" content="follow, index" />
                  <Title>{getMetaTitle()}</Title>
                  <Link
                    rel="canonical"
                    href={`https://www.myhubble.money/buy/${params.brandKey && !params.brandKey.endsWith("-gift-card") ? `${params.brandKey}-gift-card` : `${params.brandKey}`}`}
                  />
                  <Meta name="og:type" content="product" />
                  <Meta name="og:title" content={getMetaTitle()} />
                  <Meta
                    name="description"
                    content={`${routeData()!.brandExtraMetadata.voucherDescription}`}
                  />
                  <Meta
                    name="og:description"
                    content={`${routeData()!.brandExtraMetadata.voucherDescription}`}
                  />
                  <Meta
                    name="og:image"
                    content={`${routeData()!.brand.voucherProductMetadata.iconImageUrl}`}
                  />
                  <MetadataTags routeData={routeData} />
                  <SeoSchema routeData={routeData} />
                  <Show
                    when={getUIConfig().showBreadcrumbsNav}
                    fallback={<Spacer height={30} />}
                  >
                    <BreadcrumbsNav
                      breadcrumbs={[
                        {
                          label: "HOME",
                          href: getHomePageUrl(),
                        },
                        ...(!config.customizedSDKClients.limitedCategoryContent.includes(
                          routeData()?.clientId!
                        )
                          ? [
                              {
                                label:
                                  routeData()!.brandExtraMetadata.categoryTitle?.toUpperCase() ??
                                  "SHOP",
                                href: routeData()!.brandExtraMetadata
                                  .categoryName
                                  ? getCategoryPageBasePath() +
                                    getCategorySlugFromCategoryName(
                                      routeData()!.brandExtraMetadata
                                        .categoryName
                                    )
                                  : getHomePageUrl(),
                              },
                            ]
                          : []),
                        {
                          label:
                            routeData()!.brand.voucherProductMetadata.title.toUpperCase(),
                          href: "#",
                          active: true,
                        },
                      ]}
                    />
                  </Show>
                  <BrandTitle
                    brandName={routeData()!.brand.voucherProductMetadata.title}
                    discountPercentage={
                      getSimulateTransactionDetails(routeData)?.discountDetails
                        .hubbleBaseDiscount.percentage!
                    }
                    showShoppingVoucher={false}
                    showPercOff={routeData()!.brand.rewardType != "POSTPAID"}
                  />
                  <Show
                    when={
                      routeData()!.brand.voucherProductMetadata?.headingSubtitle
                    }
                  >
                    <h1 class="line-clamp-1 pt-2 text-normal text-textNormal">
                      {
                        routeData()!.brand.voucherProductMetadata
                          ?.headingSubtitle
                      }
                    </h1>
                  </Show>
                </div>
                <Spacer height={20} />
                <div class="flex flex-col gap-5 lg:gap-6">
                  <Show
                    when={
                      routeData()!.brand.voucherProductMetadata.isVoucherAssured
                    }
                  >
                    <div class="mx-4 lg:hidden ">
                      <HubbleAssured />
                    </div>
                  </Show>

                  <div
                    class="flex flex-col gap-4  bg-baseTertiaryMedium p-4 lg:p-0"
                    style={
                      routeData()?.clientConfig &&
                      (routeData()!.brand.name == "Amazon" ||
                        routeData()!.brand.name == "Amazon shopping")
                        ? {
                            "border-radius": "20px",
                          }
                        : { "border-radius": "24px" }
                    }
                  >
                    <Switch>
                      <Match
                        when={
                          routeData()?.brand.voucherProductMetadata.heroImageUrl
                        }
                      >
                        <HubbleImage
                          class="aspect-[541/316] w-full rounded-3xl lg:h-[334px]"
                          src={
                            routeData()!.brand.voucherProductMetadata
                              .heroImageUrl
                          }
                          alt={routeData()!.brand.voucherProductMetadata.title}
                        />
                      </Match>
                      <Match when={true}>
                        <VoucherGenerationBrandCard
                          plainLogoUrl={
                            routeData()!.brand.voucherProductMetadata
                              .plainLogoUrl
                          }
                          cardBackgroundColor={
                            routeData()!.brand.voucherProductMetadata
                              .cardBackgroundColor
                          }
                          showHubbleLogo={
                            getUIConfig().showHubbleLogoOnBrandCard
                          }
                        />
                      </Match>
                    </Switch>
                    <div class="lg:hidden">{buildDenominationGrid()}</div>
                  </div>

                  <div class="mx-4 flex flex-col gap-5 lg:mx-0">
                    <SecondaryCtas
                      howToUseSteps={
                        routeData()!.brand.voucherProductMetadata.howToUseSteps
                      }
                      tncResId={
                        routeData()!.brand.voucherProductMetadata.tncResId
                      }
                    />
                    <Show when={getUIConfig().showWhatsappTip}>
                      <div class="lg:hidden">
                        <WhatsappTip />
                      </div>
                    </Show>
                    <Show when={getUIConfig().showEmailTip}>
                      <div class="lg:hidden">
                        <EmailTip />
                      </div>
                    </Show>
                  </div>

                  <div class="mx-[18px] flex flex-col gap-7 lg:mx-2 lg:gap-8">
                    <Divider />
                    <AboutThisVoucher
                      voucherDescription={
                        routeData()!.brandExtraMetadata.voucherDescription
                      }
                      voucherHighlights={
                        routeData()!.brandExtraMetadata.voucherHighlights
                      }
                    />
                    <Show when={getUIConfig().showHubbleTrustMarker}>
                      <Divider />
                      <TrustMarkerHappyUsers />
                    </Show>
                    <Show
                      when={routeData()!.brandExtraMetadata.brandDescription}
                    >
                      <Divider />
                      <AboutTheBrand
                        brandName={
                          routeData()!.brand.voucherProductMetadata.title
                        }
                        brandDescription={
                          routeData()!.brandExtraMetadata.brandDescription
                        }
                        thumbnailUrl={
                          routeData()!.brand.voucherProductMetadata.iconImageUrl
                        }
                      />
                    </Show>

                    <Show
                      when={routeData()!.brandExtraMetadata.faqs.length > 0}
                    >
                      <Divider />
                      <Faqs
                        faqs={routeData()!.brandExtraMetadata.faqs}
                        headerText="FAQs"
                      />
                    </Show>
                    <Show
                      when={
                        routeData()?.sdkMetaData?.mode !== "sdk" &&
                        routeData()!.brandExtraMetadata.similarBrands.length > 0
                      }
                    >
                      <Divider />
                      <SimilarBrands
                        similarBrands={routeData()!.brandExtraMetadata.similarBrands.slice(
                          0,
                          20
                        )}
                      />
                    </Show>
                  </div>
                </div>
              </div>

              <div class="hidden lg:flex lg:w-[398px] lg:flex-col">
                <Spacer height={18} />
                <div
                  class="gap-5 lg:sticky lg:flex lg:flex-col"
                  classList={{
                    "lg:top-[92px]":
                      routeData()!.brand.voucherProductMetadata
                        .isVoucherAssured,
                    "lg:top-[20px]":
                      !routeData()!.brand.voucherProductMetadata
                        .isVoucherAssured && !isClientAxis(),
                    "lg:top-10": isClientAxis(),
                  }}
                >
                  <Show
                    when={
                      routeData()!.brand.voucherProductMetadata.isVoucherAssured
                    }
                    fallback={<Spacer height={52} />}
                  >
                    <HubbleAssured />
                  </Show>
                  {buildDenominationGrid()}
                  <Show when={getUIConfig().showWhatsappTip}>
                    <WhatsappTip />
                  </Show>
                  <Show when={getUIConfig().showEmailTip}>
                    <EmailTip />
                  </Show>
                </div>
              </div>
            </div>
            <Show when={getUIConfig().showFooterNotes}>
              <BrandL2FooterNotes
                footerList={routeData()!.brandExtraMetadata.footNotes}
              />
            </Show>
            <Show
              when={getUIConfig().showFooter}
              fallback={<Spacer height={40} />}
            >
              <div class="lg:px-40">
                <Footer />
              </div>
            </Show>
            <Show when={getUIConfig().showBrandingOnBottom}>
              <HubbleBranding class=" w-full py-4" />
            </Show>
          </div>
        </Show>
      </div>
    </>
  );

  function buildDenominationGrid() {
    return (
      <DenominationsGrid
        discountPercentage={
          getSimulateTransactionDetails(routeData)?.discountDetails
            .totalDiscount.percentage!
        }
        initialErrorOnSimulate={initialErrorOnSimulate}
        dryRunErrorMessage={dryRunErrorMessage}
        selectedAmount={selectedAmount}
        setSelectedAmount={setSelectedAmount}
        selectedVariantId={selectedVariantId}
        setSelectedVariantId={setSelectedVariantId}
        isSimulating={isSimulating}
        initiateOrder={onClickGetThisCard}
        useRewardCoins={useRewardCoins}
        setUseRewardCoins={setUseRewardCoins}
        ctaState={ctaState}
        allowRewardCoins={getMode() === "hubble" && isLoggedIn()}
        showHubbleCoinRewards={
          routeData()?.brand.rewardType === "POSTPAID" && getMode() === "hubble"
        }
        hubbleCoinRewards={hubbleCoinRewards}
        dueAmount={dueAmount}
        redemptionAmount={redemptionAmount}
        brand={routeData()!.brand}
        isCoinsOnly={
          getSimulateTransactionDetails(routeData)?.paymentSource ===
            "COINS_ONLY" || getMode() === "rnr"
        }
        coinsBalance={routeData()?.coinsSummary?.totalAvailable ?? 0}
        rewardDiscountAmount={rewardDiscountAmount}
        isRewardTypePostPaid={routeData()!.brand.rewardType === "POSTPAID"}
        dividerClass="px-4 lg:px-6"
      />
    );
  }
}
