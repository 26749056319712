import { Show } from "solid-js";
import { Optional } from "~/types";
import HubbleImage from "~/widgets/hubble_image";

type AboutTheBrandProps = {
  brandName: string;
  brandDescription: Optional<string>;
  thumbnailUrl: string;
};

export function AboutTheBrand(props: AboutTheBrandProps) {
  return (
    <div class="flex flex-col gap-4 lg:flex-row ">
      <div class="lg:hidden">
        <HubbleImage
          class="h-[100px] w-[90px] rounded-[13.79px]"
          src={props.thumbnailUrl}
          alt={props.brandName + " logo"}
        />
      </div>
      <div class="flex shrink grow basis-0 flex-col gap-2">
        <h2 class="text-h5 text-textDark lg:text-h4">
          About {props.brandName}{" "}
        </h2>

        <div class="text-medium text-basePrimaryDark lg:text-normal">
          <Show when={props.brandDescription} fallback={<></>}>
            {props.brandDescription}
          </Show>
        </div>
      </div>
      <div class="hidden w-fit lg:flex">
        <HubbleImage
          class="h-[100px] w-[90px] rounded-[13.79px]"
          src={props.thumbnailUrl}
          alt={props.brandName + " logo"}
        />
      </div>
    </div>
  );
}
